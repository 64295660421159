import {
  DEFAULT_MODAL_DISCLOSURE,
  ModalDisclosure,
} from '@/models/ModalDisclosure';
import {Dispatch, ReactNode, SetStateAction, createContext} from 'react';

type SuccessModalOptions = {
  disableCountdown?: boolean;
};

export class SuccessContextProps {
  public readonly disclosure: ModalDisclosure;
  private readonly setSuccessMessage: Dispatch<
    SetStateAction<string | ReactNode>
  >;
  private readonly setDisableCountdown: Dispatch<SetStateAction<boolean>>;

  constructor(
    disclosure: ModalDisclosure = DEFAULT_MODAL_DISCLOSURE,
    setSuccessMessage: Dispatch<SetStateAction<string | ReactNode>> = () => '',
    setDisableCountdown: Dispatch<SetStateAction<boolean>> = () => false
  ) {
    this.disclosure = disclosure;
    this.setSuccessMessage = setSuccessMessage;
    this.setDisableCountdown = setDisableCountdown;
  }

  showSuccess(
    message: string | ReactNode = '',
    options: SuccessModalOptions = {}
  ) {
    const {disableCountdown = false} = options; // Default value for disableCountdown is false
    this.setSuccessMessage(message);
    this.setDisableCountdown(disableCountdown);
    this.disclosure.onOpen();
  }
}

export const SuccessContext = createContext(new SuccessContextProps());
