import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import Button from '../components/Button/Button';
import CityIDModal from './CityIDModal';
import {SuccessIcon} from './icons/SuccessIcon';
import {KeyboardEvent, ReactNode, useContext} from 'react';
import {SuccessContext} from '@/context/SuccessContext';
import React, {useEffect, useState} from 'react';

type SuccessModalProps = {
  children: ReactNode | undefined;
  disableCountdown?: boolean; // New prop to disable countdown, default to false
};

export default function SuccessModal({
  children,
  disableCountdown = false, // Default to false
}: SuccessModalProps) {
  const context = useContext(SuccessContext);
  const disclosure = context.disclosure;
  const [countdown, setCountdown] = useState(5);

  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    disclosure.onClose();
  };

  React.useEffect(() => {
    if (disclosure.isOpen && !disableCountdown) {
      setCountdown(5);
      const timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            disclosure.onClose();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [disclosure.isOpen, disableCountdown]);

  return (
    <CityIDModal
      isOpen={disclosure.isOpen}
      onOpenChange={disclosure.onOpenChange}
      onKeyDown={onKeyDown}
    >
      <ModalContent>
        {(onClose: any) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-center">
              Success
            </ModalHeader>
            <ModalBody>
              <div className="flex justify-center items-center">
                <SuccessIcon />
              </div>
              <div className="text-center mb-4">{children}</div>
            </ModalBody>
            <ModalFooter className="flex justify-center items-center">
              <Button label="OK" onClick={onClose} variant="primary" />
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CityIDModal>
  );
}
