import React from 'react';
import styles from './Button.module.css';

type ButtonProps = {
  label: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'success' | 'danger';
  style?: React.CSSProperties;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = 'button',
  variant = 'primary',
  style,
  disabled,
}) => {
  const variantClass = variant ? styles[variant] : '';
  const hoverClass =
    variant === 'success'
      ? styles.successHover
      : variant === 'danger'
        ? styles.dangerHover
        : '';
  const disabledClass = disabled ? styles.disabled : '';

  return (
    <button
      type={type}
      className={`${styles.customButton} ${variantClass} ${hoverClass} ${disabledClass}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
